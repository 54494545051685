import React, { Component } from "react";
import { PopUp } from "./PopUp";
import './css/styles.css';
//import {initialCards} from "./datatest"
const initialCards = [
  {
    orderNumber: "1",
    orderFio: "Драничников Александр Юрьевич",
    orderSumma: "10 540",
    orderMerchant: "ИП Дудкин",
    orderData: "19.11.22",
    orderStatus: "",
  },
  {
    orderNumber: "2",
    orderFio: "Петрович Павел Николаевич",
    orderSumma: "12 040",
    orderMerchant: "ИП Дудкин",
    orderData: "19.11.22",
    orderStatus: "D",
  },
  {
    orderNumber: "3",
    orderFio: "Драничников Александр Юрьевич!",
    orderSumma: "17 870",
    orderMerchant: "ИП Дудкин",
    orderData: "19.11.22",
    orderStatus: "C",
  },
];
const popUp = new PopUp();
class CreateCards extends Component {
  constructor(props) {
    super(props);
    this.stateVendor ();
    this.state = {
      vendorId: null,
      classNameLi: "rite__info",
      popUpOrder: "popup info-order"
    };
  }

  
 stateVendor = () =>{
fetch(`https://appmp.kubatura.ru:1381/api_proxy.php?type=vendors/${this.props.vendor}`, {
      method: "GET",
      headers: {
        Authorization:
          "Bearer Y2VvQGt1YmF0dXJhLnJ1OjRNMjg5Nm1tNjMyYzhDQk82MzBodTdIMTM1Mm1CRjRL",
        "Content-type": "application/json; charset=UTF-8",
      },
    })
    .then((res) => res.json())
    .then((res) => {
      this.setState({vendorId: res.company})
  })
 }
 popUpInfo =(e) =>{
    this.setState({classNameLi: "rite__info activ"});
    this.setState({popUpOrder: "popup info-order popup_open"});
 }
 handleKeyDown = (e) =>{
  if (e.key === 'Escape') {
    this.setState({popUpOrder: "popup info-order"});
  }
 }
  render() {
    let { vendor, firstname, lastname, summa, data } = this.props;
    let stat = "";
    //dataNow = Date.now(data);
    if (status == "C") {
      stat = "Оплачен";
    } else {
      stat = "Открыт";
    }    
    //////////////////////////////////////////////////////////////////////////////////
    return (
      <>
        <li className={this.state.classNameLi} onClick={this.popUpInfo}>
          <div className="conten__info-status">{stat}</div>
          <div className="conten__info-fio">
            {firstname} {lastname}
          </div>
          <div className="conten__info-summa">{summa}</div>
          <div className="conten__info-merchant">{this.state.vendorId}</div>
          <div className="conten__info-data">{data}</div>
          <div className={this.state.popUpOrder} tabIndex={0} onKeyDown={this.handleKeyDown}>
               <div className="popup__card-container">
                   <div className="number-order">Номер</div>
                   <div className="fio-order">{firstname} {lastname}</div>
                   <div className="summa-order">{summa}</div>
                   <div className="merchant-order">{this.state.vendorId}</div>
                   <div className="data-rder">{data}</div>
                </div>
          </div>
        </li>
      </>
    );
  }
}

export class OrderCS extends Component {
  constructor(props){
    super(props);
    this.state = {
      order : props.cardItem,
    }
  }

  addInfoOrder = () => {
     console.log(this.props)
  };
  element =  this.props.cardItem.map((item) => {
    return (
      <CreateCards
        status={item.status}
        firstname={item.firstname}
        lastname={item.lastname}
        summa={item.total}
        vendor={item.company_id}
        data={item.timestamp}
      />
    );
  });
 render(){
    return (
    <div>
      <div id="zakaz" className="rite__content zakaz">
        <div className="rite__content-info">
          <button id="" onClick={this.addInfoOrder}>
          Обновить список
          </button>
          <div className="rite__content-number">Статус</div>
          <div className="rite__content-fio">Покупатель</div>
          <div className="rite__content-summa">Цена</div>
          <div className="rite__content-merchant">Вендор</div>
          <div className="rite__content-data">Дата</div>
        </div>
        <lu className="rite__info-main">{this.element}</lu>
      </div>
    </div>
  );
 }
};
