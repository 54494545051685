import React from 'react';
import { Base64 } from 'js-base64';
import './css/styles.css';

export let encoderInfo = 'Y2VvQGt1YmF0dXJhLnJ1OkFQT0dMMzQzMDc1UkNEVTVEWkJGOHlTMDA4NVA3NDQ2';
export class LoginCS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
  };
  apiLoginCscart = (e) => {
    e.preventDefault();
   // console.log(datares);
    var object = {};
    const formVendor = document.forms.apiloginCscart;
    let data = new FormData(formVendor);
    data.forEach(function (value, key) {
      object[key] = value;
    });
    console.log(object);
    this.setState({submitted: true});
    let datares = `${object.name}:${object.apikey}`;
    const la = Base64.encode(datares);
    encoderInfo = la;
   };
   encodeInfo = (e) =>{
    console.log(encoderInfo);
   };
 
   render() {
    if(this.state.submitted){
      return(
        <div>
          <p>Пароль введен</p>
          <button onClick={this.encodeInfo}>показать Key</button>
        </div>
      );
    }else{
      return (
       <div>
        <form id="apiloginCscart" onSubmit={this.apiLoginCscart}>
          <p>
            <input type="text" name="name" placeholder={"Логин"}></input>
          </p>
          <p>
            <input type="text" name="apikey" placeholder="API key"></input>
          </p>
          <button className="menu-item" id="logonCS">Логин</button>
        </form>
     </div>
      )};
    }
  };
//export default LoginCS; 

