import React from 'react';
import { AddTask } from './AddTask';
import  {LoginCS}  from './LoginCS';
import {AddUser} from  './AddVendor';
import {MenuHeader} from './MenuHeader';
import { RegUser } from './RegUser'; 
import {OrderCS} from './OrderCS';
import { PopUp } from './PopUp';
import Logo from './images/logo.jpg';
import './css/styles.css';

let cardItem = [];
 await fetch("https://appmp.kubatura.ru:1381/api_proxy.php?type=orders", {
  method: "GET",
  headers: {
    Authorization:
      "Bearer Y2VvQGt1YmF0dXJhLnJ1OjRNMjg5Nm1tNjMyYzhDQk82MzBodTdIMTM1Mm1CRjRL",
    "Content-type": "application/json; charset=UTF-8",
  },
})
  .then((res) => res.json())
  .then((res) => {
    cardItem = res.orders;
    console.log(res);
  });

console.log(cardItem);

const App = () => {
  
  return  <div>
    <header className='header__items'>
      <div>
         <img src={Logo} alt="React Logo" />
      </div>
      <div>
         <p className="header__items-text">Административная пането Маркет плейса</p>
      </div>
    </header>
       <div className="menu-header">
          <MenuHeader />
       </div>
      <div className="vendor-header-menu">
         <AddTask />
         <LoginCS />
       </div>
       <div className="main-content vendor-prise-block">  
          <AddUser />
       </div>
       <div className="main-content hilde-menu">  
          <RegUser />
       </div>
       <div className="main-content hilde-menu zac">  
          <OrderCS  cardItem = {cardItem}/>
       </div>
       <PopUp />
  </div>;
};

export default App;
