import React from "react";
import BX24 from "bx24-api";
import{encoderInfo} from './LoginCS';
import './css/styles.css';

export const AddTask = () => {

  function bitrix(data) {
    var dt = new Date();
    BX24.callMethod(
      'tasks.task.add', 
	      {
          fields:{
            TITLE: `Проверить прайс лист ${data.vendor} ${dt.toLocaleString()}`,
            DESCRIPTION: `Продавец ${data.vendor} давно не обновляли товары на Витрине сайта. Нужно связаться и узнать причины.`,
            RESPONSIBLE_ID: data.managerbx,
            CREATED_BY: 11, // Руководитель отдела аккаунт менеджер
          }}
    ).then(res => {
        const bodyData = {taskid: res.answer.result.task.id};
        //console.log(bodyData);
      fetch(`https://appmp.kubatura.ru:3443/updatetaskid/${data.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      })
        .then((response) => response.json())
    });
  }

  function proverka(data, itemApi) {
    let now = Date.now();
    if (+data.updated_timestamp + 3600 < now / 1000) {
      console.log("Все хорошо проверка по времени пройдена, задача будет поставлена");
      bitrix(itemApi);
    } else {
      console.log("Еще рано обновлять");
      let a = +data.updated_timestamp + 3600;
      let b = now / 1000;
      console.log(a);
      console.log(b);
    }
  }

  function apimod(itemApi) {
    const reqest = new XMLHttpRequest();
    reqest.open(
      "GET",
      `https://appmp.kubatura.ru:1381/api_proxy.php?type=products/${itemApi.productid}`
    );
    reqest.setRequestHeader("Content-type", "application/json; charset=utf8");
    reqest.setRequestHeader(
      "Authorization",
      `Bearer ${encoderInfo}`
    );
    reqest.send();
    reqest.addEventListener("load", () => {
      if (reqest.status == 200) {
        var data = JSON.parse(reqest.response);
        proverka(data, itemApi);
      } else {
        console.log("Ошибка запроса");
      }
    });
  }
function statusTask (item){
  BX24.callMethod(
    'tasks.task.get', 
    {taskId:`${item.taskid}`, select:['*']}, 
  )
   .then(respons => {
    const result = respons.answer.result.task.status;
    if (result == 5 || result == null){
      apimod(item);
    }else{
      console.log("Задача еще не завершена старая , новая не будет назначена");
      
    }
})
};

  function perebor(data) {
    data.forEach(function (item) {
      console.log(item);
     if (item.taskid == "" || item.taskid == null){
      apimod(item);
     }else{
      statusTask(item);
     }
     
    });
  }
  const add = () => {
    fetch("https://appmp.kubatura.ru:3443/getvendor")
      .then((res) => res.json())
      .then((res) => perebor(res));
  };
   // Проверка кнопки прайса когда нажимали последний раз
    function buttonPriseProverka(){
      fetch("https://appmp.kubatura.ru:3443/timedb/8")
      .then((res) => res.json())
      .then((res) => res.forEach((item) =>{
          if (item.timeitem > 100){
            const btn = document.querySelector('#PriseProverka');
            btn.setAttribute('disabled', '');
              console.log(item);
          }else{
            console.log("0")
          }
      })  
      );
    };
   buttonPriseProverka();
   //startApp();
  return (
      <div>
        <button id="PriseProverka" onClick={add}>
          Проверить обновление прайс листа
        </button>
        {/* <button id="PriseProverkaTas" onClick={addTas}>
          Тест
        </button> */}
      </div>
  );
};
