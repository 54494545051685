import React, { Component } from "react";
import './css/styles.css';


export class PopUp extends Component {
  constructor(props){
    super(props);
    this.state = {
        className: "popup__card-container hilde-menu"
    }
  }


  render (){
    return (<></>
    )    
  }

  };