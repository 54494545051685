import React from 'react';
import ReactDOM from 'react-dom';
import './css/styles.css';

export const MenuHeader = () => {
    const Prise = (e) => {
        const menuItem = document.querySelectorAll('.menu-item'),
              maincontent = document.querySelectorAll('.main-content');
              maincontent.forEach(item =>{
                item.classList.add("hilde-menu");
              })
              maincontent[0].classList.remove("hilde-menu");
       console.log(maincontent);
    };
    const RegUserSystem = (e) => {
        const menuItem = document.querySelectorAll('.menu-item'),
              maincontent = document.querySelectorAll('.main-content');
              maincontent.forEach(item =>{
                item.classList.add("hilde-menu");
              })
              maincontent[1].classList.remove("hilde-menu");
       console.log(maincontent);
    };
    const OrdersCS = (e) => {
        const menuItem = document.querySelectorAll('.menu-item'),
        maincontent = document.querySelectorAll('.main-content');
        maincontent.forEach(item =>{
          item.classList.add("hilde-menu");
        })
        maincontent[2].classList.remove("hilde-menu");
 console.log(maincontent);
    };

  
    return ( <div className='menu-items'>
    <button className='menu-item' onClick={Prise}>Прайс</button>
    <button className='menu-item' onClick={RegUserSystem}>Сотрудники</button>
    <button className='menu-item' onClick={OrdersCS}>Заказы</button>
    </div>);
  };