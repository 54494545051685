import React from "react";
import './css/styles.css';

const manageruser = [
  {
    name: "Тимошенко",
    managerid: 19,
  },
  {
    name: "Ореолов",
    managerid: 11,
  },
  {
    name: "Драничников",
    managerid: 465,
  },
  {
    name: "Иванова",
    managerid: 25,
  },
];

function userserch(data) {
  manageruser.forEach(function (item) {
    let a = data;
    if (item.name === data.managername) {
      a["managerbx"] = item.managerid;
      console.log(a);
    }
  });
}
function vendorspisok(data) {
  let div = document.querySelector(".vendor-prise-deskrip");
  let elp = div.querySelectorAll("p");
  if (elp.length > 0) {
    elp.forEach((elem) => elem.remove());
  }
  data.forEach(function (item) {
    let div = document.querySelector(".vendor-prise-deskrip"),
      p = document.createElement("p");
    p.innerHTML = `Компания:<strong>"${item.vendor}"</strong> , Товар:"${item.productid}", Ответственный: ${item.managername}`;
    div.append(p);
  });
}
function startApp() {
  fetch("https://appmp.kubatura.ru:3443/getvendor")
    .then((res) => res.json())
    .then((res) => vendorspisok(res));
}
//startApp();

const CreateVendor = ({ vendor, productid, managername }) => {
  let stat = "";
  if (status == "C") {
    stat = "Оплачен";
  } else {
    stat = "Не определен";
  }

  return (
    <>
      <div className="rite__info ">
        <div className="conten__info-vepprice">Компания:<strong>"{vendor}"</strong> , Товар:"{productid}", Ответственный: {managername}</div>
      </div>
    </>
  );
};
let cards;
await fetch("https://appmp.kubatura.ru:3443/getvendor")
  .then((res) => res.json())
  .then((res) => {
    cards = res;
  });

export const AddUser = () => {
  function vendorAdd(e) {
    e.preventDefault();
    var objectdata = {};
    const formVendor = document.forms.vendorAdd;
    let data = new FormData(formVendor);
    data.forEach(function (value, key) {
      objectdata[key] = value;
    });
    userserch(objectdata);
    if (objectdata.managerbx > 0) {
      var json = JSON.stringify(objectdata);
      const reqest = new XMLHttpRequest();
      reqest.open("POST", "https://appmp.kubatura.ru:3443/addvendor");
      reqest.setRequestHeader("Content-type", "application/json; charset=utf8");
      reqest.send(json);
      formVendor.reset();
    } else {
      alert(
        'Компания не добавлена, проверте Поле "Фамилия менеджер". формат заполнения "Иванов"'
      );
    }
  }

  //Кнопка списка показа Вендоров
  function vendorsp(e) {
    e.preventDefault();
    fetch("https://appmp.kubatura.ru:3443/getvendor")
      .then((res) => res.json())
      .then((res) => vendorspisok(res));
  }


  const element = cards.map((item) => {
    return (
      <CreateVendor
        vendor={item.vendor}
        productid={item.productid}
        managername={item.managername}
      />
    );
  });
  return (
    <>
      <div className="vendor-prise-item">
        <h2>Добавить вендора для проверки</h2>
        <form id="vendorAdd" onSubmit={vendorAdd}>
          <p>
            <input
              type="text"
              name="vendor"
              required=""
              placeholder="Наименование продавца"
            ></input>
          </p>
          <p>
            <input
              type="text"
              name="productid"
              placeholder="ID Проверяемого продукта"
            />
          </p>
          <p>
            <input
              type="text"
              name="managername"
              placeholder="Фамилия менеджер"
            />
          </p>
          <p>
            <input id="addVen" type="submit" />
          </p>
        </form>
      </div>
      <div id="vendorspisok" className="vendor-prise">
        <h2>Список добавленных</h2>
        <div className="vendor-prise-deskrip">{element}</div>
        <button id="vendorspisok" onClick={vendorsp}>
          Обновить список
        </button>
      </div>
    </>
  );
};
